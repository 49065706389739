<template>
  <div>
    <div class="vx-row flex mb-2">
      <div class="vx-col w-full" align="right">
        <vs-button
          align="right"
          @click="createCategoryHandler"
          icon-pack="feather"
          icon="icon-edit"
        >Add Category</vs-button>
      </div>
    </div>
    <CategoryList
      :categoryData="categoryData"
      :totalDocs="totalDocs"
      :page="page"
      :popUpDeleteParent="popUpDelete"
      :noDataText="noDataText"
      @fetchList="getCategoriesList"
      @editCategory="editCategory"
      @deleteCategory = "deleteCategory"
    />
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import CategoryList from "../../../components/WebStore/categories/list";

  export default {
    components: {
      CategoryList
    },
    data() {
      return {
        totalDocs: 0,
        page: 1,
        popUpDelete:false,
        categoryData: [],
        noDataText: 'Loading...'
      };
    },
    methods: {
      ...mapActions("category", [
        "getCategoryList",
      "deleteCategoryById"
      ]),
      ...mapActions("ecommerce",["algoliaSync"]),
      async getCategoriesList(dataTableParams) {
        let self = this;
        this.getCategoryList(dataTableParams).then( async (res) => {
          self.categoryData = res.data.data.docs;
          self.totalDocs = res.data.data.pagination.total || 0;
          self.page = res.data.data.pagination.page;
          self.currentPage = res.data.data.pagination.page;
          self.noDataText = 'No Category Available'
        });
      },
      createCategoryHandler(){
        this.$router.push({ name: 'AdminAddCategory'})
      },
      editCategory(id) {
        this.$router.push({
          name: "AdminEditCategory",
          params: { categoryId: id }
        });
      },
      async deleteCategory(data){
        let self=this;
        await this.deleteCategoryById(data.id)
          .then(res => {
            self.algoliaSync()
            self.popUpDelete = false;
            self.getCategoriesList(data.dataTableParams);
            this.$vs.notify({
              title: "Success",
              text: "Category Deleted successfully.",
              color: "success"
            });
          })
          .catch(err => {
            self.popUpDelete = false;
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger"
            });
          });
      }
    }
  };
</script>
